import _get from "lodash/get"
import { store } from "@/store/index"
import { apim } from "@/constants/api"
import {
  addToCart,
  createCart,
  getCart,
  setCart,
  setCartCount,
  shareCartDelete,
  shareCartaddToCart,
} from "@/store/features/cartSlice"
import { getConfig } from "@/constants/config"
import { getQuantityCount } from "@/utils/cart"
import { showPreviewCart, showToast } from "@/store/features/genericSlice"
import { getCurrency, getUserPersona } from "@/utils/helper"
import { getAttributeValue } from "@/js/helperV2"
import { getShippingMethods } from "@/components/cart/cartHelper"

const { shareCartEndPoint } = require("@/constants/index")
import { CATALOG_PROJECTION_ENDPOINT } from "@/constants/routes"

export const getProductsBySku = async skus => {
  let query = ""
  skus.forEach((sku, i) => {
    query += `"${sku}"`
    if (i + 1 !== skus.length) query += ", "
  })
  const res = await apim.get(
    `${CATALOG_PROJECTION_ENDPOINT}?where=masterVariant(sku in (${query})) or variants(sku in (${query}))`
  )
  if (res && res.data && res.data.results) return res.data.results
  return []
}

export const getSharedCart = async id => {
  const { data = false } = await apim.get(`${shareCartEndPoint}/${id}`)
  if (data) {
    const lineitems = data?.lineitems.map(item => {
      return {
        ...item,
        roomInfo: item?.roomInfo ? JSON.stringify(item.roomInfo) : "",
      }
    })
    const sharedCart = {
      ...data,
      lineitems,
    }
    return sharedCart
  }
}

const showToastHandler = msg =>
  store.dispatch(
    showToast({
      message: msg,
      isVisible: true,
    })
  )

export const shareaddToCart = async (items, message, callback, clockID) => {
  const payload = []
  const CONFIG = await getConfig()
  const salesChannels = CONFIG?.salesChannelConfig?.map ?? {}
  items.forEach((product = {}) => {
    const {
      isBundleProduct = false,
      isBundle = false,
      sku = "",
      quantity = 0,
      brand = "",
    } = product

    const fields = {
      salesChannel: salesChannels[brand] ?? "KOUS",
      productSkuOption: product.productSkuOption,
    }
    if (product.backorder) fields.isBackOrderable = true
    payload.push({
      action: isBundleProduct
        ? "addBundleItem"
        : isBundle
        ? "addbundle"
        : "addLineItem",
      sku,
      quantity,
      custom: product.additionalInfo
        ? {
            type: { key: "lineItemShipping" },
            fields: {
              salesChannel: salesChannels[product.brand],
              roomInfo: product.additionalInfo.roomInfo,
              customQuantity: product.additionalInfo.customQuantity,
              overage: product.additionalInfo.overage,
              productSkuOption: product.productSkuOption,
            },
          }
        : {
            type: { key: "lineItemShipping" },
            fields: fields,
          },
    })
  })

  if (clockID) {
    payload.push({
      action: "setCustomType",
      type: { key: "order", typeId: "type" },
      fields: { CSRAssociateId: clockID, orderType: "ISC" },
    })
  }
  const body = {
    actions: payload,
  }
  await store
    .dispatch(addToCart(body))
    .unwrap()
    .then(res => {
      if (res) {
        store.dispatch(setCart(res))
        const quantityLineItems = res?.lineItems
        const payload = getQuantityCount(quantityLineItems)
        store.dispatch(setCartCount(payload))
        if (message) {
          showToastHandler(message)
        }
        if (callback) {
          callback({ res })
        }
      }
    })
    .catch(err => {
      callback(err)
    })
}

export const handleSharedCartItems = (
  cartItems = [],
  products = [],
  info = {}
) => {
  const {
    currencyCode = "",
    locale = "",
    comboSkus: comboSkusArg = [],
    productBrands: productBrandsArg = [],
  } = info
  let subtotal = 0
  const specFiles = []
  const cartData = []
  const comboSkus = [...comboSkusArg]
  const productBrands = [...productBrandsArg]

  if (products.length > 0) {
    cartItems
      .filter(item => !item.custom?.fields?.bundleParentId)
      .forEach((item, i) => {
        let product = {}
        let productVariant = {}

        // Check if product is master variant
        if (products.find(product => product.masterVariant.sku === item.sku)) {
          product = products.find(
            product => product.masterVariant.sku === item.sku
          )
          productVariant = product.masterVariant
        } else {
          product =
            products.find(product =>
              product.variants.find(variant => variant.sku === item.sku)
            ) ?? {}
          productVariant =
            product.variants?.find(variant => variant.sku === item.sku) ?? {}
        }

        // Get product details
        const attributes = productVariant.attributes ?? []
        let priceObj = productVariant.prices?.find(
          price =>
            price.value.currencyCode === currencyCode &&
            price.customerGroup?.obj?.key === getUserPersona()
        )
        if (!priceObj?.value) {
          priceObj = productVariant.prices?.find(
            price =>
              price.value.currencyCode === currencyCode && !price.customerGroup
          )
        }
        const unitPrice = _get(priceObj, "value.centAmount", 0)
        cartData[i] = {
          id: product?.id ?? "",
          sku: item.sku,
          slug: product?.slug ? product.slug[locale] : "",
          quantity: item.qty,
          image: item.thumbnailImage ?? "",
          category: _get(
            attributes.find(attr => attr.name === "ProductLocalCategory"),
            "value",
            ""
          ),
          brand: _get(
            attributes.find(attr => attr.name === "CustomerFacingBrand"),
            "value",
            ""
          ),
          name: _get(
            attributes.find(attr => attr.name === "RegionBrandName"),
            "value",
            _get(item, `skuName`, "")
          ),
          desc: _get(
            attributes.find(
              attr => attr.name === "ProductDescriptionProductShort"
            ),
            `value[${locale}]`,
            _get(
              attributes.find(
                attr => attr.name === "ProductDescriptionProduct"
              ),
              "value",
              ""
            )
          ),
          color: _get(
            attributes.find(attr => attr.name === "SKUColorFinishName"),
            `value[${locale}]`,
            ""
          ),
          unitPrice: Number((unitPrice / 100).toFixed(2)),
          totalPrice: Number(((unitPrice * item.qty) / 100).toFixed(2)),
          customerFacingSKU: getAttributeValue(attributes, "CustomerFacingSKU"),
        }

        // Check if product is a combo sku
        comboSkus[item.sku] =
          attributes.findIndex(attr => attr.name === "ComboSKU") !== -1
        productBrands[item.sku] = _get(
          attributes.find(attr => attr.name === "CustomerFacingBrand"),
          "value",
          ""
        )

        // Calculate cart subtotal
        subtotal = subtotal + Number(((unitPrice * item.qty) / 100).toFixed(2))

        // Get spec files for all line items
        const productResources = _get(
          attributes.find(attr => attr.name === "ProductResource"),
          "value",
          []
        )
        if (productResources.length > 0) {
          const resource =
            productResources.find(arr => arr[1].value === "SpecPDFFileName") ??
            []
          const resourceUrl =
            resource.find(obj => obj.name === "ResourceFullWebURL") ?? {}
          const specFileUrl = resourceUrl.value ?? ""
          if (specFileUrl) specFiles.push(specFileUrl)
        }
      })
    return {
      cartData,
      specFiles,
      subtotal,
      comboSkus,
      productBrands,
    }
  }
}

export const getShareCartaddToCart = async (
  items = [],
  showPreview = false,
  message = {},
  callback = () => {},
  taxCalculate = false,
  clockID = "",
  isSharedCart = false
) => {
  const { itemAddedToCart = "", addToCartErrorMsg = "" } = message
  const payload = []
  let userZipCode
  const CONFIG = await getConfig()
  const isAuthenticated = store.getState()?.auth.isAuth
  const salesChannels = CONFIG?.salesChannelConfig?.map ?? {}
  items.forEach(product => {
    const {
      brand = "",
      backorder = false,
      isBundle = "",
      sku = "",
      quantity = "",
      additionalInfo = "",
    } = product
    const fields = {
      salesChannel: salesChannels[brand.toLowerCase()] ?? "KOUS",
    }
    if (backorder) fields.isBackOrderable = true
    payload.push({
      action: isBundle ? "addbundle" : "addLineItem",
      sku: sku,
      quantity: quantity,
      custom: additionalInfo
        ? {
            type: { key: "lineItemShipping" },
            fields: {
              salesChannel: salesChannels[brand],
              roomInfo: additionalInfo.roomInfo,
              customQuantity: additionalInfo.customQuantity,
              overage: additionalInfo.overage,
            },
          }
        : {
            type: { key: "lineItemShipping" },
            fields: fields,
          },
    })
    userZipCode = product?.userZipCode
  })

  const {
    id = "",
    version = 0,
    customerGroup = "",
    shippingAddress: { postalCode = "" } = {},
  } = await generateCartData(true)

  if (taxCalculate && postalCode) payload.push({ action: "calculateTax" })
  if (!isAuthenticated && !customerGroup) {
    payload.push({
      action: "setCustomerGroup",
      customerGroup: {
        key: "GST",
      },
    })
  }
  if (clockID) {
    payload.push({
      action: "setCustomType",
      type: { key: "order", typeId: "type" },
      fields: { CSRAssociateId: clockID, orderType: "ISC" },
    })
  }
  const body = {
    version: version,
    actions: payload,
    cartId: id,
    isSharedCart,
  }
  if (userZipCode) {
    body.servicesku = true
    body.zipCode = userZipCode
  }
  let atcStatus
  await store
    .dispatch(shareCartaddToCart(body))
    .unwrap()
    .then(res => {
      if (res) {
        atcStatus = { res }
        if (itemAddedToCart) {
          showToastHandler(itemAddedToCart)
        }
        if (showPreview) store.dispatch(showPreviewCart())
        if (callback) {
          callback({ message: "success", res })
        }
        if (taxCalculate && postalCode) {
          store.dispatch(getShippingMethods())
        }
        if (!isAuthenticated && res.anonymousId)
          localStorage.setItem("anonymousId", res.anonymousId)
      }
    })
    .catch(() => {
      store.dispatch(showToastHandler(addToCartErrorMsg))
    })
  return atcStatus
}

export const deleteCart = async errMsg => {
  const errorMsg = "Failed to delete cart"
  try {
    const { id = "", version = 0 } = await generateCartData({
      isNotFromCart: true,
    })

    return store.dispatch(shareCartDelete({ id, version }))
  } catch (err) {
    console.log(errorMsg, err)
    store.dispatch(showToastHandler(errMsg))
  }
}

export const generateCartData = async arg => {
  const currency = await getCurrency()
  const body = {
    currency: currency,
    taxMode: "ExternalAmount",
  }

  let cartData = {}
  const { payload: rsp } = await store.dispatch(getCart(arg))

  if (rsp?.error && rsp?.cartExists === "false") {
    const { payload } = await store.dispatch(createCart(body))
    cartData = payload
  } else {
    cartData = arg === true ? rsp : rsp?.data?.me?.activeCart
  }

  return cartData
}
